import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { useEffect } from 'react'
import { useLinxNotFoundView } from 'src/sdk/linx/hooks/useLinxNotFoundView'
import NotFound from 'src/components/NotFound/NotFound'
import { getStandardObject } from 'src/utils/amplitude/getStandardObject'
import { getFeatureObject } from 'src/utils/amplitude/getFeatureObject'
import { dispatchAmplitudeEvent } from 'src/utils/amplitude'

function Page() {
  const { sendNotFoundViewEvent } = useLinxNotFoundView()

  useEffect(() => {
    sendNotFoundViewEvent()
  }, [sendNotFoundViewEvent])

  const standardObject = getStandardObject()

  const featureObject = getFeatureObject({
    locationOnPage: 'NA',
    section: '404',
    name: 'página não encontrada',
  })

  useEffect(() => {
    dispatchAmplitudeEvent({
      eventName: 'Website Error Triggered',
      eventData: {
        ...standardObject,
        ...featureObject,
      },
    })
  }, [featureObject, standardObject])

  return (
    <>
      <GatsbySeo />
      <NotFound />
    </>
  )
}

export default Page
